import React, { useEffect, useState } from "react";
import DesktopLayout from "./DesktopLayout";
import { BREAKPOINTS } from "../../constants";
import MobileLayout from "./MobileLayout";
import { SocketProvider } from "../../context/SocketContext";
import ChatContextProvider from "./context/ChatContext";
import Inbox from "./Inbox";


export default function Messages() {

    const [user, setUser] = useState('')
    const [isMobile, setIsMobile] = useState(window.innerWidth <= BREAKPOINTS.MOBILE);


    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => window.removeEventListener('resize', handleWindowSizeChange);
    }, [])


    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= BREAKPOINTS.MOBILE)
    }


    return (
        <SocketProvider>
            <Inbox />
        {/* {
        isMobile ? <MobileLayout user={user} setUser={setUser} /> :
            <DesktopLayout setUser={setUser} />
        } */}
        </SocketProvider>
    )
}