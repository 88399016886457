// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getToken, getCSRF_Token } from "./localStorageService";
import { API_URL, INITIAL_TOKEN } from "../../config/config";

// using CSRF token from Redux Store

// Define a service using a base URL and expected endpoints
export const paymentApi = createApi({
    reducerPath: "paymentApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_URL,

        prepareHeaders: (headers, { getState }) => {
            headers.set("token", INITIAL_TOKEN);
            headers.set("Authorization", `Bearer ${getToken()}`);
            headers.set("Accept", "application/json");
            return headers;
        },
    }),
    // mode: "no-cors",
    credentials: "include",
    tagTypes: ["carddetail"],

    endpoints: (builder) => ({
        paymentMethod: builder.mutation({
            query: (data) => {
                return {
                    url: "card",
                    method: "POST",
                    body: data,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
            invalidatesTags: ["carddetail"],
        }),
        purchaseCoins: builder.mutation({
            query: (data) => {
                return {
                    url: "purchase/coins",
                    method: "POST",
                    body: data,
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                };
            },
        }),
        getPaymentMethod: builder.query({
            query: () => {
                return {
                    url: "card",
                    method: "GET",
                };
            },
            providesTags: ["carddetail"],
        }),
        getCoinsConversation: builder.query({
            query: () => {
                return {
                    url: "setting/COINS_CONVERSION",
                    method: "GET",
                };
            },
        }),
        deletePaymentMethod: builder.mutation({
            query: (slug) => {
                return {
                    url: `card/${slug}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["carddetail"],
        }),
    }),
});

export const {
    usePaymentMethodMutation,
    useGetPaymentMethodQuery,
    useDeletePaymentMethodMutation,
    useGetCoinsConversationQuery,
    usePurchaseCoinsMutation,
} = paymentApi;
