import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash"
import styles from "./styles/Inbox.module.scss"
import { CardTitle, Input } from "../../Components/CustomComponents";
import { v4 as uuidv4 } from "uuid"
import { FiSend } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { ImAttachment } from "react-icons/im";
import { TiCamera } from "react-icons/ti";
import { useSocket } from "../../context/SocketContext";
import { useChatContext } from "./context/ChatContext";
import { useSelector } from "react-redux";
import { ErrorToast } from "../../Hooks/useToast";
import { useInView } from "react-intersection-observer";


export default function Inbox({ isMobile = false, setUser }) {
    const socket = useSocket();
    const { ref, inView } = useInView({
        threshold: 0,
    });
    const { isSocketConnected, user } = useSelector(state => state.authInfo)

    const INITIAL_CHAT_HISTORY = useMemo(() => {
        return {
            isError: false,
            isLoading: true,
            data: []
        }
    }, [])

    const messageRef = useRef();
    const [page, setPage] = useState(1);
    const [chatHistory, setChatHistory] = useState(INITIAL_CHAT_HISTORY);
    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const hours = date.getHours().toString().padStart(2, '0'); // Adds leading zero if needed
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    // console.log("Is socket connected : ", isSocketConnected, user);

    // useEffect(() => {
    //     if (isSocketConnected) {
    //         socket.on('loadChatHistory_', (data) => {
    //             console.log("Chat history loaded : ", data);

    //             setChatHistory(prev => {
    //                 return {
    //                     ...prev,
    //                     isLoading: false,
    //                     data: (data?.links?.current > 1) ? [...prev?.data, data?.data] : data?.data
    //                 }
    //             })
    //         })
    //     }

    //     return () => {
    //         if (isSocketConnected) {
    //             socket.dispose('loadChatHistory_')
    //         }
    //     }

    // }, [isSocketConnected])


    // useEffect(() => {
    //     if (isSocketConnected) {
    //         socket.on('receivedMessage_', (data) => {
    //             const message = data?.data
    //             if (_.isUndefined(data?.payload?.message_uuid)) {
    //                 setChatHistory(prev => {
    //                     return {
    //                         ...prev,
    //                         data: [
    //                             ...prev?.data,
    //                             message
    //                         ]
    //                     }
    //                 })
    //             }
    //             else {
    //                 setChatHistory(prev => {
    //                     return {
    //                         ...prev,
    //                         data: [
    //                             ...prev?.data.filter(item => item.message_uuid !== data?.payload.message_uuid),
    //                             message
    //                         ]
    //                     }
    //                 })
    //             }

    //             socket.emit('_resetMessageCount')
    //         })
    //     }

    //     return () => {
    //         if (isSocketConnected) {
    //             socket.dispose('receivedMessage_')
    //         }
    //     }

    // }, [isSocketConnected])

    useEffect(() => {
        if (isSocketConnected) {
            socket.on('loadChatHistory_', (data) => {
                console.log("Chat history loaded : ", data);

                setChatHistory(prev => {
                    return {
                        ...prev,
                        isLoading: false,
                        data: (data?.links?.current > 1) ? [...data?.data, ...prev?.data] : data?.data
                    }
                })
            })
        }

        return () => {
            if (isSocketConnected) {
                socket.dispose('loadChatHistory_')
            }
        }

    }, [isSocketConnected])

    useEffect(() => {
        if (isSocketConnected) {
            socket.on('receivedMessage_', (data) => {
                const message = data?.data
                if (_.isUndefined(data?.payload?.message_uuid)) {
                    setChatHistory(prev => {
                        return {
                            ...prev,
                            data: [
                                message, // Add new message at the beginning
                                ...prev?.data
                            ]
                        }
                    })
                }
                else {
                    setChatHistory(prev => {
                        return {
                            ...prev,
                            data: [
                                message, // Add new message at the beginning if updating an existing message
                                ...prev?.data.filter(item => item.message_uuid !== data?.payload.message_uuid)
                            ]
                        }
                    })
                }

                socket.emit('_resetMessageCount')
            })
        }

        return () => {
            if (isSocketConnected) {
                socket.dispose('receivedMessage_')
            }
        }

    }, [isSocketConnected])

    const handleSendMessage = async () => {
        const message = messageRef.current.value;
        console.log(message, 'BOAD');

        if (!message) return;

        try {
            const payload = {}
            payload.message_type = 'TEXT';
            payload.message = message
            payload.message_uuid = uuidv4()
            setChatHistory(prev => {
                return {
                    ...prev,
                    data: [
                        {
                            ...payload,
                            user_slug: user.slug,
                            user_name: user.name,
                            user_image: user.image_url,
                            message_timestamp: new Date(),
                        },
                        ...prev?.data // Add new message at the beginning
                    ]
                }
            })
            socket.emit('_sendMessage', payload);
            messageRef.current.value = ''
        }
        catch (err) {
            ErrorToast(err?.message);
        }
    }

    useEffect(() => {
        if (inView) {
            setPage(prev => prev + 1);
        }
    }, [inView])

    useEffect(() => {
        if (page > 1) {
            socket.emit("_loadChatHistory", { page: page })
        }
    }, [page])


    useEffect(() => {
        socket.emit("_loadChatHistory")
    }, [])

    useEffect(() => {
        scrollChatContainer()
    }, [chatHistory?.data.length])


    const handleBackButtonClick = () => {
        setUser('');
    }


    const scrollChatContainer = () => {
        const container = document.getElementById('chatContainer')
        container.scroll({ top: container.scrollHeight, behavior: 'smooth' });
    }

    // const handleSendMessage = async () => {
    //     const message = messageRef.current.value;
    //     console.log(message, 'BOAD');

    //     if (!message) return;

    //     try {
    //         const payload = {}
    //         payload.message_type = 'TEXT';
    //         payload.message = message
    //         payload.message_uuid = uuidv4()
    //         setChatHistory(prev => {
    //             return {
    //                 ...prev,
    //                 data: [
    //                     ...prev?.data,
    //                     {
    //                         ...payload,
    //                         user_slug: user.slug,
    //                         user_name: user.name,
    //                         user_image: user.image_url,
    //                         message_timestamp: new Date(),
    //                     }
    //                 ]
    //             }
    //         })
    //         socket.emit('_sendMessage', payload);
    //         messageRef.current.value = ''
    //     }
    //     catch (err) {
    //         ErrorToast(err?.message);
    //     }
    // }

    console.log(chatHistory);

    return (
        <div className={styles.Inbox}>
            <div className={styles.titleContainer}>
                <div className={styles.userContainer}>
                    <div className={styles.imageContainer}>
                        {isMobile ?
                            <span
                                className={styles.backButton}
                                onClick={handleBackButtonClick}
                            >
                                <IoIosArrowBack />
                            </span> : ''}

                        <img
                            className={styles.image}
                            src={'/images/logo.png'}
                            alt="User Profile"
                        />
                        <span className={styles.online}></span>

                    </div>
                    <div className={styles.nameContainer}>
                        <CardTitle
                            text={'Customer Support'}
                            classes="lc-1"
                        />
                        {/* <p className={styles.online}>Online</p> */}

                    </div>

                </div>

            </div>
            <div className={styles.chatContainer} id="chatContainer">
                {chatHistory?.isLoading ? <div className="w-100 h-100 d-flex align-items-center justify-content-center text-white"><Spinner /></div> :
                    _.isEmpty(chatHistory?.data) ? <div className="w-100 h-100 d-flex align-items-center justify-content-center"><p className="m-0 text-white fs-5">Chat Messages Not Found</p></div> :
                        chatHistory?.data?.map((item, index) => {
                            const isMe = item["user_slug"] === user?.slug;
                            return (
                                <div
                                    className={`${styles.chat} ${isMe ? styles.me : ''}`}
                                    ref={
                                        (index === (chatHistory?.data.length - 1)) ? ref : null}
                                >
                                    <span className={styles.info}>{formatTimestamp(item?.message_timestamp)}</span>
                                    <p
                                        className={`${styles.message} ${isMe ? styles.me : ''}`}
                                    >
                                        {item?.message}
                                    </p>
                                    {/* <span className={styles.info}>Seen</span> */}
                                </div>
                            )
                        })}

            </div>

            <div className={styles.messageContainer}>
                {/* <InputGroup >

                    <Form.Control

                        size='lg'
                        className={` mt-1 mb-2 gilroyFont messageBar`}
                        placeholder='Type a message'
                        type='text'
                    // onChange={onChange}
                    />
                    <div className="input-before-icon">
                        <HiOutlineEmojiHappy />
                    </div>

                    <div className="input-after-icon">
                        <ImAttachment />
                        <TiCamera />
                    </div>

                </InputGroup> */}

                <InputGroup>
                    <Form.Control
                        size="lg"
                        className={`mt-1 mb-2  messageBar`}
                        placeholder={'Type a message'}
                        type={'text'}
                        ref={messageRef}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                handleSendMessage();
                                e.stopPropagation()
                            }
                        }}
                    />
                </InputGroup>
                <button className={`${styles.sendButton} `} onClick={handleSendMessage}>
                    <FiSend />
                </button>

            </div>

        </div>
    )
}