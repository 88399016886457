// const BASE_URL = "http://localhost:3001";
const BASE_URL = "http://api.dubbgames.trangotechdevs.com:3005";
const SOCKET_BASE_URL = "http://chat.dubbgame.trangotechdevs.com:3040"
// const BASE_URL = "https://api.dubbsgames.com";
const NAKAMA_SCHEME = 'http';
const NAKAMA_HOST = '31.186.241.41';

const API_URL = BASE_URL + "/api/user/";

const INITIAL_TOKEN = "ee0a260d-417f-45b6-acd7-f003d3ad386d";

export {
    BASE_URL,
    NAKAMA_SCHEME,
    NAKAMA_HOST,
    API_URL,
    INITIAL_TOKEN,
    SOCKET_BASE_URL
};
