import React, { useContext, useEffect } from "react";
import socket from "../config/socket";
import { getToken, removeToken } from "../Data/services/localStorageService";
import { useDispatch, useSelector } from "react-redux";
import { logout, socketConntected } from "../Data/Features/authSlice";

// import { updateKey } from "../features/DefaultReducer";


const SocketContext = React.createContext();

export function useSocket() {
    return useContext(SocketContext);
}


export function SocketProvider({ children }) {
    const {  isSocketConnected } = useSelector(state => state.authInfo)
    const dispatch = useDispatch();

    useEffect(() => {
        const token = getToken()
        if (!isSocketConnected) {
            socket.connectToSocket(
            token,
                () => {
                    dispatch(logout())
                    removeToken();
                },
                () => {
                    dispatch(socketConntected())
                });
        }

        return () =>{
            if (!isSocketConnected) {
                socket?.disconnect();
            } 
        } 
    }, [])

    useEffect(() => {
        if (isSocketConnected) {
            socket.on('newRoom_', (data) => {
                socket.emit('_joinRoom', data?.data?.chat_room_slug)
            })
        }

        return () => {
            if (isSocketConnected) {
                socket.dispose('newRoom_')
            }

        }
    }, [isSocketConnected])


    return <SocketContext.Provider value={socket}>
        {children}
    </SocketContext.Provider>
}